<template>
  <div>
    <verification-video-list-top-nav />

    <div>
      <v-data-table
        :items="dataList"
        :pagination.sync="pagination"
        :loading="isLoading"
        :total-items="total"
        :rows-per-page-items="[10, 25, 50, 100]"
        class="elevation-1"
        no-data-text="Sorry, there are no results"
        :headers-length="4"
      >
        <template slot="items" slot-scope="props">
          <td class="py-2 video-img-td">
            <router-link
              v-if="props.item.processing === 0"
              :to="`/moderation/verification/video/${props.item.id}`"
              class="blue-grey--text bold link-hover"
            >
              <img :src="props.item.screen_url" alt="" class="video-img">
            </router-link>
            <div v-else class="process">
              video in process
            </div>
          </td>
          <td class="break">
            <router-link
              :to="`/moderation/user_list/user/${props.item.user.id}`"
              class="blue-grey--text bold link-hover"
            >
              {{ props.item.user.username }}
            </router-link>
          </td>
          <td class="date-td">
            {{ dateFormat(props.item.date) }}
          </td>
          <td class="actions">
            <span class="mr-4 green-text fw-600 status">Accepted</span>
            <v-btn
              :disabled="props.item.processing === 1"
              color="warning"
              class="ma-0"
              :to="`/moderation/verification/video/${props.item.id}`"
            >
              Details
            </v-btn>
          </td>
        </template>
      </v-data-table>

      <div class="text-xs-center pt-2">
        <v-pagination
          v-model="pagination.page"
          :length="pages"
          @input="refreshPagination"
        />
      </div>
    </div>
  </div>
</template>

<script>
import VerificationVideoListTopNav from "../VerificationVideoListTopNav"
import SavesPagination from "../../../../../mixins/SavesPaginationMixin"
import {getVerificationVideosRequest} from "../../../../../api/verificationVideo"
import {fetchData} from "../../../../../utils/response"
import moment from "moment"
export default {
  name: "AcceptedVerificationVideos",
  components: {
    VerificationVideoListTopNav,
  },
  mixins: [SavesPagination],
  data() {
    return {
      userId: null,
      pagination: {},
      isLoading: false,
      total: 0,
      dataList: [],
      search: null,
    }
  },
  computed: {
    pages() {
      if (this.pagination.rowsPerPage == null || this.total == null) return 0

      return Math.ceil(this.total / this.pagination.rowsPerPage)
    }
  },
  watch: {
    /**
     * Watch pagination changes and load more data
     */
    pagination(pagination) {
      this.$paginationCacheSave(pagination.page, pagination.rowsPerPage)
      this.refresh()
    }
  },
  mounted() {
    const pagination = this.$paginationCacheGet()
    this.pagination.page = pagination.page
    this.pagination.rowsPerPage = pagination.per_page
  },
  created() {
    this.$bus.on("user-search-input", this.onSearchUser)
  },
  destroyed() {
    this.search = null
    this.$bus.off("user-search-input", this.onSearchUser)
  },
  methods: {
    async refresh() {
      const params = {
        page: this.pagination.page,
        per_page: this.pagination.rowsPerPage,
        search: this.search,
        status: 'accepted'
      }
      this.isLoading = true
      this.dataList = await getVerificationVideosRequest(params)
          .then(response => {
            this.total = response.data.meta.total
            return response
          })
          .then(fetchData)
          .then(data =>
              data.map(item => {
                return item
              })
          )
          .catch(this.$notificationHandleResponseError)

      this.isLoading = false
    },
    refreshPagination() {
      this.refresh()
      this.$paginationCacheSave(
          this.pagination.page,
          this.pagination.rowsPerPage
      )
    },
    onSearchUser(searchUserValue) {
      this.search = searchUserValue
      this.pagination.page = 1
      this.refreshPagination()
    },
    dateFormat(date) {
      return moment(date).format("DD-MM-YYYY")
    }
  },

}
</script>

<style scoped>
.process{
  width: 100px;
  height: 64px;
  background: #212121;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  text-align: center;
}
.link-hover:hover {
  color: #fff !important;
  text-decoration: none !important;
}
.status{
  font-size: 18px;
}
.actions{
  width: 224px;
  text-align: right;
}
.video-img{
  max-height: 140px;
}
.video-img-td{
  min-width: 160px;
}
.date-td{
  min-width: 110px;
}
</style>